@tailwind base;
@tailwind components;
@tailwind utilities;
@import "leaflet/dist/leaflet.css";


/* Hide horizontal scrollbar in modern browsers (Chrome, Safari, Firefox, Edge) */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome/Safari */
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* If your map library sets a very high z-index, force it lower. */
.mapboxgl-map,
.leaflet-container,
.map-container {
  z-index: 0 !important;
}

